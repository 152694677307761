import {
  ICreateDealChildEnrollmentClassInput,
  ICreateUpdateClassesListInput,
  IDealChildEnrollmentClass,
  IUpdateDealChildEnrollmentClassInput
} from 'src/types/deal'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class DealChildEnrollmentClassService extends CRUDService<IDealChildEnrollmentClass> {
  protected apiPath = '/api/app/deal-child-enrollment-class'

  getParentCart(request: any) {
    return instanceApi.get(`${this.apiPath}/parent-cart`, {
      params: request
    })
  }

  createChildClasses(request: ICreateDealChildEnrollmentClassInput) {
    return instanceApi.post(
      `${this.apiPath}/deal-child-enrollment-classes`,
      request
    )
  }

  updateChildClasses(request: IUpdateDealChildEnrollmentClassInput) {
    return instanceApi.put(
      `${this.apiPath}/deal-child-enrollment-classes`,
      request
    )
  }

  createUpdateClassesList(request: ICreateUpdateClassesListInput) {
    return instanceApi.post(
      `${this.apiPath}/create-update-classes-list`,
      request
    )
  }
}
