/* eslint-disable no-unused-vars */

import { ICalendarEventUser } from 'src/types/calendarEvent'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class CalendarEventUserService extends CRUDService<ICalendarEventUser> {
  protected apiPath = '/api/app/calendar-event-user'

  getByAnonymousUser(anonymousUserId: string) {
    return instanceApi.get<ICalendarEventUser>(
      `${this.apiPath}/by-anonymous-user/${anonymousUserId}`
    )
  }

  getByParent() {
    return instanceApi.get<ICalendarEventUser>(`${this.apiPath}/by-parent`)
  }
}
